import {
    AuthenticationResult,
    BrowserCacheLocation,
    Configuration,
    EventMessage,
    EventType,
    ProtocolMode,
    PublicClientApplication
} from "@azure/msal-browser";
import Config from "../helpers/Config";

const configuration: Configuration = {
    auth: {
        protocolMode: ProtocolMode.OIDC,
        clientId: Config.OAUTH_CLIENT_ID,
        authority: Config.OAUTH_AUTHORITY,
        knownAuthorities: [Config.OAUTH_AUTHORITY],
        postLogoutRedirectUri: typeof window !== "undefined" ? window.location.origin : undefined,
        redirectUri: typeof window !== "undefined" ? window.location.origin : undefined
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        secureCookies: true,
        storeAuthStateInCookie: true
    }
};

export const authClient = new PublicClientApplication(configuration);

const accounts = authClient.getAllAccounts();
const accountEnvironment = new URL(Config.OAUTH_AUTHORITY).hostname;
const account = accounts.find(account=>account.environment === accountEnvironment);
if (account) {
    authClient.setActiveAccount(account);
}

authClient.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        authClient.setActiveAccount(account);
    }
});
