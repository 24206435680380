import * as React from "react";
import {LocatieModel} from "../../../redux/toestel/types";
import {useTranslation} from "../../../helpers/i18nUtils";


interface ToestelLocatieProps {
    locatie: LocatieModel
}

const ToestelLocatie: React.FC<ToestelLocatieProps> = ({locatie}) => {
    const {t} = useTranslation("toestel");

    if (!locatie.gebouw && !locatie.verdieping && !locatie.lokaalNummer && !locatie.beschrijving) {
        return <span className="text-muted">{t("algemeen:Labels.onbekend", "Onbekend")}</span>
    }

    return <span>
                <span>{locatie.beschrijving}</span><br/>
                <span
                    className='text-muted'>{locatie.gebouw} {locatie.verdieping} {locatie.lokaalNummer}</span>
            </span>;
};

export default ToestelLocatie;
