import {default as React, useState} from "react";
import {RootState} from "../../../../redux/reducers";
import {connect} from 'react-redux';
import {hideModal} from "../../../../redux/ui/actions";
import {Button, ButtonGroup, CloseButton, Col, Form, FormGroup, Row} from "react-bootstrap";
import DatumSelector from "../../../../components/aqualex/DatumSelector";
import {Field, Formik} from 'formik';
import * as Yup from 'yup';
import Datum from "../../../../components/aqualex/Datum";
import moment from 'moment';
import {SwitchFormField} from "../../../../components/aqualex/form/SwitchFormField";
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../../components/aqualex/Modal";
import {DagPlanningFase} from "../../../../redux/planning/types";
import Config from "../../../../helpers/Config";
import {HelpTooltip} from "../../../../components/aqualex/HelpTooltip";
import {useTranslation} from "../../../../helpers/i18nUtils";
import {Trans} from "react-i18next";
import {SelectFormField} from "../../../../components/aqualex/form/SelectFormField";

const StartAutomatischePlanningModal = ({
                                            onBevestigHandler,
                                            hideModal,
                                        }) => {
    const {t} = useTranslation("planning");

    const [advancedMode, setAdvancedMode] = useState(false);

    return (
        <Modal show={true} onHide={hideModal} size='lg' autoFocus>
            <ModalHeader closeButton={false}>
                <ModalTitle>{t("StartAutomatischePlanningModal.automatische-planning-starten", "Automatische planning starten")}</ModalTitle>
                <div className="pull-right">
                    <ButtonGroup toggle>
                        <Form.Switch id='advancedModeToggle'
                                     checked={advancedMode}
                                     label={t("Labels.geavanceerd", "Geavanceerd")}
                                     onChange={(e) => {
                                         setAdvancedMode(e.currentTarget.checked as boolean);
                                     }}
                        />
                        <CloseButton onClick={hideModal}/>
                    </ButtonGroup>
                </div>
            </ModalHeader>

            {advancedMode
                ? <AdvancedModalBody
                    onBevestigHandler={(van, tot, oplosTijd, achterstandMeeNemen, automatischToepassen, planningsFase) => {
                        hideModal();
                        onBevestigHandler(van, tot, oplosTijd, achterstandMeeNemen, automatischToepassen, planningsFase);
                    }}
                    hideModal={hideModal}/>
                : <SimpleModalBody onBevestigHandler={(van, tot) => {
                    hideModal();
                    onBevestigHandler(van, tot, 0, true, true);
                }}
                                   hideModal={hideModal}/>}

        </Modal>
    );
};

const mapStateToProps = (state: RootState, ownProps) => ({
    onBevestigHandler: ownProps.onBevestigHandler,
});

const mapDispatchToProps = {
    hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(StartAutomatischePlanningModal);

enum PeriodeKeuze {
    WEEK = 'WEEK',
    TWEE_WEKEN = 'TWEE_WEKEN',
    MAAND = 'MAAND',
    TWEE_MAANDEN = 'TWEE_MAANDEN',
}

const SimpleModalBody = ({
                             onBevestigHandler,
                             hideModal,
                         }) => {
    const {t} = useTranslation("planning");

    const morgen = moment(new Date()).add(1, 'day').toDate()

    const [tot, setTot] = useState(moment(morgen).add(1, 'week').toDate());

    const updateVanTot = (keuze: PeriodeKeuze) => {
        let newTot = morgen;

        switch (keuze) {
            case PeriodeKeuze.WEEK:
                newTot = moment(morgen).add(1, 'week').toDate()
                break;
            case PeriodeKeuze.TWEE_WEKEN:
                newTot = moment(morgen).add(2, 'week').toDate()
                break;
            case PeriodeKeuze.MAAND:
                newTot = moment(morgen).add(1, 'month').toDate()
                break;
            case PeriodeKeuze.TWEE_MAANDEN:
                newTot = moment(morgen).add(2, 'month').toDate()
                break;
        }

        setTot(newTot);
    };

    return <React.Fragment>
        <ModalBody>
            <h5>{t("Labels.periode", "Periode")}</h5>
            <Form>
                <Form.Check id='periodeKomendeWeek'
                            name='keuze'
                            value={PeriodeKeuze.WEEK}
                            type='radio'
                            onChange={(e) => updateVanTot(e.currentTarget.value as PeriodeKeuze)}
                            label={t("Labels.komende-week", "Komende week")}
                            defaultChecked/>
                <Form.Check id='periodeKomende2Weken'
                            name='keuze'
                            value={PeriodeKeuze.TWEE_WEKEN}
                            type='radio'
                            onChange={(e) => updateVanTot(e.currentTarget.value as PeriodeKeuze)}
                            label={t("Labels.komende-2-weken", "Komende 2 weken")}/>
                <Form.Check id='periodeKomendeMaand'
                            name='keuze'
                            value={PeriodeKeuze.MAAND}
                            type='radio'
                            onChange={(e) => updateVanTot(e.currentTarget.value as PeriodeKeuze)}
                            label={t("Labels.komende-maand", "Komende maand")}/>
                <Form.Check id='periodeKomende2Maanden'
                            name='keuze'
                            value={PeriodeKeuze.TWEE_MAANDEN}
                            type='radio'
                            onChange={(e) => updateVanTot(e.currentTarget.value as PeriodeKeuze)}
                            label={t("Labels.komende-2-maanden", "Komende 2 maanden")}/>
                <Form.Text>
                    <Trans t={t} i18nKey="StartAutomatischePlanningModal.automatische-planning-berekenen-van-tot">
                        Automatische planning zal berekend worden van <Datum datum={morgen} showTime={false}/> t.e.m. <Datum
                        datum={tot} showTime={false}/>
                    </Trans>
                </Form.Text>
            </Form>
        </ModalBody>
        <ModalFooter>
            <Button variant='light' onClick={hideModal}>{t("Buttons.annuleer", "Annuleer")}</Button>{' '}
            <Button variant='primary'
                    onClick={() => onBevestigHandler(morgen, tot)}>{t("Buttons.opstarten", "Opstarten")}</Button>
        </ModalFooter>
    </React.Fragment>;
}

const AdvancedModalBody = ({
                               onBevestigHandler,
                               hideModal,
                           }) => {

    const {t, language} = useTranslation("medewerker");

    const minimaleOplosTijd = 0;
    const minimaalIngevoerdeOplostijd = 10;
    const maximaleOplostijd = 60 * 60;

    const schema = Yup.object({
        van: Yup.date()
            .required(t("Foutmeldingen.van-datum-is-verplicht", 'Van datum is verplicht') as string).nullable(),
        tot: Yup.date()
            .required(t("Foutmeldingen.tot-datum-is-verplicht", 'Tot datum is verplicht') as string).nullable()
            .when('van', (van, schema) => van && Yup.date()
                .required()
                .nullable()
                .min(van, t("Foutmeldingen.tot-datum-mag-niet-voor-van-datum-liggen", 'Tot datum moet gelijk of later dan de van datum zijn') as string)),
        oplosTijd: Yup.number()
            .required(t("Foutmeldingen.oplostijd-is-verplicht", 'Oplostijd is verplicht') as string).nullable()
            .min(minimaleOplosTijd,
                t("Foutmeldingen.oplostijd-moet-minstens-0-zijn", 'Oplostijd moet groter of gelijk zijn dan 10s (of gelijk aan 0)') as string)
            .max(maximaleOplostijd,
                t("Foutmeldingen.maximale-oplostijd",
                    'Oplostijd moet kleiner of gelijk zijn dan {{maximum}} (1u)',
                    {maximum: Intl.NumberFormat(language, {style: "unit", unit: "second"}).format(maximaleOplostijd)}
                ) as string)
            .test('oplosTijd', t("Foutmeldingen.minimale-oplostijd",
                    "Oplostijd moet groter of gelijk zijn dan {{minimum}} (of gelijk aan 0)",
                    {minimum: Intl.NumberFormat(language, {style: "unit", unit: "second"}).format(minimaalIngevoerdeOplostijd)}) as string,
                function (oplostijd) {
                    return oplostijd === 0 || oplostijd >= 10;
                } as any)
    });

    const morgen = moment(new Date()).add(1, 'day').toDate();

    return (
        <Formik validationSchema={schema}
                onSubmit={values => onBevestigHandler(values.van, values.tot, values.oplosTijd, values.achterstandMeeNemen, values.automatischToepassen, values.dagPlanningFase)}
                initialValues={{
                    van: morgen,
                    tot: moment(morgen).add(1, 'month').toDate(),
                    oplosTijd: 0,
                    achterstandMeeNemen: true,
                    automatischToepassen: true,
                    dagPlanningFase: undefined
                }}>
            {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
              }) => (
                <Form noValidate>
                    <ModalBody>
                        <h5>{t("StartAutomatischePlanningModal.medewerkers", "Medewerkers")}</h5>
                        <Trans i18nKey={"StartAutomatischePlanningModal.omschrijving"}>
                            <p>De planning wordt berekend voor alle medewerkers met een IN ONTWERP dagplanning waarbij
                                "Automatisch aanvullen" actief is, en die voldoen aan volgende filters:</p>
                        </Trans>
                        <Form.Row>
                            <FormGroup id="dagPlanningFase" as={Col} xs="12">
                                <SelectFormField name="dagPlanningFase"
                                                       label={t("Labels.planningsfase", "Planningsfase")}
                                                       options={Object.values(DagPlanningFase).map(item => ({label: Config.labels.dagPlanningFase[item], value: item}))} emptyLabel="Alle..."/>
                            </FormGroup>

                            <FormGroup id='van' as={Col}>
                                <Form.Label
                                    htmlFor='vanaf'>{t("StartAutomatischePlanningModal.periode-van", "Periode van")}</Form.Label>
                                <Field name='van' id='van' type='date'
                                       isValid={!errors.van}
                                       isInvalid={!!errors.van}
                                >
                                    {({field: {value}, form: {setFieldValue}}) => (
                                        <DatumSelector value={value}
                                                       onChange={datum => setFieldValue('van', datum)}
                                                       isInvalid={!!errors.van}/>
                                    )}
                                </Field>
                                <Form.Control.Feedback type='invalid'
                                                       style={{display: 'block'}}>{errors.van}</Form.Control.Feedback>
                            </FormGroup>

                            <FormGroup id='tot' as={Col}>
                                <Form.Label htmlFor='tot'>{t("StartAutomatischePlanningModal.tot", "tot")}</Form.Label>
                                <Field name='tot' id='tot' type='date'
                                       isValid={!errors.tot}
                                       isInvalid={!!errors.tot}
                                >
                                    {({field: {value}, form: {setFieldValue}}) => (
                                        <DatumSelector value={value}
                                                       onChange={datum => setFieldValue('tot', datum)}
                                                       isInvalid={!!errors.tot}/>
                                    )}
                                </Field>
                                <Form.Control.Feedback type='invalid'
                                                       style={{display: 'block'}}>{errors.tot}</Form.Control.Feedback>
                            </FormGroup>
                        </Form.Row>

                        <h5>{t("Labels.bezoekaanvragen", "Bezoekaanvragen")}</h5>
                        <p><Trans t={t}
                            i18nKey="StartAutomatischePlanningModal.bezoekaanvragen-omschrijving">
                            Alle bezoekaanvragen in status TE PLANNEN of die als ontwerp zijn gepland. De gewenste datum
                            moet met bovenstaande periode overlappen.
                        </Trans></p>
                        <Form.Row>
                            <FormGroup id='achterstandMeeNemen' as={Col}>
                                <Row>
                                    <Col xs="auto">
                                        <SwitchFormField name='achterstandMeeNemen'
                                                         label={t("Labels.planningsachterstand-meenemen", 'Achterstand meenemen')}/>
                                    </Col>

                                    <Col xs="auto">
                                        <HelpTooltip id="achterstandMeeNemen">
                                            <Trans t={t}
                                                i18nKey="StartAutomatischePlanningModal.achterstand-meenemen-tooltip">
                                            Neem ook TE PLANNEN bezoekaanvragen waarvan de gewenste datum reeds in het
                                            verleden ligt mee.
                                            </Trans>
                                        </HelpTooltip>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Form.Row>

                        <h5>{t("Labels.uitvoering", "Uitvoering")}</h5>
                        <Form.Row>
                            <FormGroup id='automatischToepassen' as={Col}>
                                <SwitchFormField name='automatischToepassen'
                                                 label={t("Labels.automatisch-toepassen", 'Automatisch toepassen')}/>
                            </FormGroup>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group id='oplosTijd' as={Col}>
                                <Form.Label>{t("Labels.oplostijd", "Oplostijd")}</Form.Label>
                                <Form.Control name='oplosTijd'
                                              type='number'
                                              value={values.oplosTijd.toString()}
                                              onChange={handleChange}
                                              isValid={!errors.oplosTijd}
                                              isInvalid={!!errors.oplosTijd}
                                />
                                <Form.Control.Feedback type='invalid'>{errors.oplosTijd}</Form.Control.Feedback>
                                <Form.Text>{t("StartAutomatischePlanningModal.automatische-bepaling", "Gebruik 0 voor automatische bepaling")}</Form.Text>
                            </Form.Group>
                        </Form.Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant='light' onClick={hideModal}>{t("algemeen:Buttons.annuleer", "Annuleer")}</Button>{' '}
                        <Button variant='primary' disabled={!isValid}
                                onClick={() => handleSubmit()}>{t("algemeen:Buttons.opstarten", "Opstarten")}</Button>
                    </ModalFooter>
                </Form>)}
        </Formik>
    );
};
