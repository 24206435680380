import {MedewerkerModel} from "../medewerker/types";
import {DagPlanningFase, Skill} from "../planning/types";

export interface PlanningTechniekerModel {
    medewerker: MedewerkerModel;
    skills: Array<Skill>;
}

export interface PlanningRequestModel {
    id: string;
    techniekers: Array<PlanningTechniekerModel>;
    totalTechniekersShiftDuration: number;
    totalOrderDuration: number;
    orders: Array<PlanningOrderModel>;
}

export interface PlanningOrderModel {
    id: string;
    bezoekAanvraagId: string;
    locatie: string;
    skills: Array<Skill>,
    duur: number;
    vroegsteDatum: Date;
    laatsteDatum: Date;
    toegelatenTechniekers: Array<string>;
    uitgeslotenTechniekers: Array<string>;
    prioriteit: number;
}

export interface PlanningVoorstelModel {
    id: string;
    creatieDatum: Date;
    bezoekVoorstellen: Array<BezoekVoorstelModel>;
    ongeplandeBezoekAanvragen: Array<string>;
}

export interface BezoekVoorstelModel {
    id: string;
    medewerkerId: string;
    bezoekAanvraagId: string;
    datum: Date;
    van: string;
    tot: string;
    afstand?: number;
}

export interface AutomatischePlanningModel {
    id: string;
    status: AutomatischePlanningStatus;
    vanDatum: Date;
    totDatum: Date;
    aangemaaktDoor: MedewerkerModel;
    creatieDatum: Date;
    oplosTijd: number;
    achterstandMeeNemen: boolean;
    automatischToepassen: boolean;
    faalStatus?: AutomatischePlanningStatus;
    faalReden?: string;
    faalTijdstip?: Date;
    planningRequest?: PlanningRequestModel;
    planningVoorstel?: PlanningVoorstelModel;
    dagPlanningFase?: DagPlanningFase;
}

export enum AutomatischePlanningStatus {
    AANGEMAAKT = 'AANGEMAAKT',
    KLAAR_VOOR_VERWERKING = 'KLAAR_VOOR_VERWERKING',
    IN_VERWERKING = 'IN_VERWERKING',
    VERWERKING_KLAAR = 'VERWERKING_KLAAR',
    GEFAALD = 'GEFAALD',
    TOEGEPAST = 'TOEGEPAST',
    GEANNULEERD = 'GEANNULEERD',
}

export interface AutomatischePlanningFilters {
    status?: AutomatischePlanningStatus;
    aangemaaktDoor?: MedewerkerModel;
}

export interface StartAutomatischePlanningForm {
    van: Date;
    tot: Date;
    oplosTijd: number;
    achterstandMeeNemen: boolean;
    automatischToepassen: boolean;
    dagPlanningFase?: DagPlanningFase;
}
