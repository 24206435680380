import React from "react";
import {Badge, Form} from "react-bootstrap";
import {EditableControl, EditableControlProps} from "./EditableControl";
import {useTranslation} from "../../helpers/i18nUtils";
import {Trans} from "react-i18next";

export interface EditableTextAreaProps extends EditableControlProps {
    rows: number;
    maxLength?: number;
}

export const EditableTextArea: React.FC<EditableTextAreaProps> = (props) => {
    const {rows, maxLength} = props;

    const {t} = useTranslation("algemeen");

    const infoText = (
        <Trans t={t} i18nKey="EditableTextArea.gebruik-ctrl-enter-om-te-bevestigen">Gebruik <Badge variant="light">Ctrl</Badge> + <Badge variant="light">Enter</Badge> om te bevestigen</Trans>
    );

    const renderRemaining = (value?: string) => {
        if (maxLength !== undefined && value) {
            const karaktersResterend = Math.max(0, Number(maxLength) - value.length);

            if (karaktersResterend > 0) {
                return (
                    <div style={{position: "absolute", right: 5, bottom: 0, transform: "translateX(-50%)", pointerEvents: "none"}}>
                        <small className="text-muted">{karaktersResterend} / {maxLength}</small>
                    </div>
                );
            }
        }

        return "";
    };

    return (
        <EditableControl {...props} infoText={infoText} style={{...props.style, position: "relative"}}>
            {({value, onChange, className, style, onSaveClick}) => {
                const onKeyUp = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
                    if (event.ctrlKey && event.key === "Enter") {
                        onSaveClick();
                    }
                };

                return (
                    <>
                        <Form.Control as="textarea"
                                      rows={rows}
                                      value={value}
                                      onChange={onChange}
                                      className={className}
                                      style={style}
                                      onKeyUp={onKeyUp}
                                      maxLength={maxLength}
                        />

                        {renderRemaining(value)}
                    </>
                );
            }}
        </EditableControl>
    );
};
