import * as React from "react";
import {connect} from 'react-redux';
import {RootState} from "../../redux/reducers";
import AnnuleerBestelBonModal from "../../pages/bestelbon/detail/AnnuleerBestelBonModal";
import KoppelVerzendAdressenModal from "../../pages/bestelbon/detail/KoppelVerzendAdressenModal";
import InstallatieStelUitModal from "../../pages/installatie/detail/InstallatieStelUitModal";
import BulkUploadDagPlanningenModal from "../../pages/medewerker/overzicht/BulkUploadDagPlanningenModal";
import StartAutomatischePlanningModal from "../../pages/planning/auto/overzicht/StartAutomatischePlanningModal";
import GenereerBezoekenModal from "../../pages/planning/opdracht/overzicht/GenereerBezoekAanvragenModal";
import {MergeBezoekAanvraagModal} from "../../pages/planning/bezoekaanvraag/detail/merge/MergeBezoekAanvraagModal";
import {
    UpdateBezoekAanvraagVanTotModal
} from "../../pages/planning/bezoekaanvraag/detail/plan/UpdateBezoekAanvraagVanTotModal";
import VerzendAdressenMapModal from "../../pages/klant/detail/VerzendAdressenMapModal";
import KoppelKlantAanBestelbonModal from "../../pages/bestelbon/detail/KoppelKlantAanBestelbonModal";
import {BevestigBestelbonModal} from "../../pages/bestelbon/detail/BevestigBestelbonModal";
import GenereerOnderhoudServiceOrderOpdrachtenModal
    from "../../pages/planning/opdracht/overzicht/GenereerOnderhoudServiceOrderOpdrachtenModal";
import BehandelServiceOrderMetDynamicsModal
    from "../../pages/planning/opdracht/detail/BehandelServiceOrderMetDynamicsModal";
import {PlanBezoekAanvraagGroepModal} from "../../pages/planning/bezoekaanvraag/groep/PlanBezoekAanvraagGroepModal";
import {DagPlanningVoegBezoekToeModal} from "../../pages/planning/dagplanning/detail/DagPlanningVoegBezoekToeModal";
import {PlanBezoekAanvraagModal} from "../../pages/planning/bezoekaanvraag/detail/plan/PlanBezoekAanvraagModal";
import {KoppelKlantModal} from "../../pages/account/detail/KoppelKlantModal";
import {NieuweKlantAccountModal} from "../../pages/account/NieuweKlantAccountModal";
import HerbezoekOnderhoudServiceOrderModal
    from "../../pages/planning/opdracht/detail/HerbezoekOnderhoudServiceOrderModal";
import NieuweCo2BestellingModal from "../../pages/co2bestelling/overzicht/NieuweCo2BestellingModal";
import {AnnuleerCo2BestellingModal} from "../../pages/co2bestelling/detail/AnnuleerCo2BestellingModal";
import {CreateKlantModalContainer} from "../../pages/klant/overzicht/CreateKlantModalContainer";
import {CreateVerzendAdresModalContainer} from "../../pages/klant/verzendadresdetail/CreateVerzendAdresModalContainer";
import {CreateBijlageModalContainer} from "./bijlage/CreateBijlageModalContainer";
import {AnnuleerOpvolgingItemModal} from "../../pages/opvolging/modals/AnnuleerOpvolgingItemModal";
import {HerprobeerOpvolgingItemModal} from "../../pages/opvolging/modals/HerprobeerOpvolgingItemModal";
import {
    NieuweAccessoireBestellingModal
} from "../../pages/accesoirebestelling/overzicht/NieuweAccessoireBestellingModal";
import {PlanOpdrachtModal} from "./PlanOpdrachtModal";
import {NieuweInterventieModal} from "../../pages/interventie/overzicht/NieuweInterventieModal";
import {AnnuleerInterventieModal} from "../../pages/interventie/detail/AnnuleerInterventieModal";
import {PlanInterventieModal} from "../../pages/interventie/detail/PlanInterventieModal";
import {VraagKlantToestemmingModal} from "../../pages/interventie/detail/VraagKlantToestemmingInterventieModal";
import {KlantAkkoordInterventieModal} from "../../pages/interventie/detail/KlantAkkoordInterventieModal";
import {NieuweDagPlanningenModal} from "../../pages/planning/dagplanning/dagoverzicht/NieuweDagPlanningenModal";
import {
    PlanningAutomatischAanvullenBevestigenModal
} from "../../pages/planning/dagplanning/detail/modal/PlanningAutomatischAanvullenBevestigenModal";
import {PlanningInterneNotitiesModal} from "../../pages/planning/dagplanning/modals/PlanningInterneNotitiesModal";
import NieuwContractModal from "../../pages/bestelbon/detail/NieuwContractModal";
import {AnnuleerAccessoireBestellingModal} from "../../pages/accesoirebestelling/AnnuleerAccessoireBestellingModal";
import {BestelBonCommssieVerdelingModal} from "../../pages/bestelbon/detail/BestelBonCommissieVerdelingModal";
import {KoppelPartnerAanBestelbonModal} from "../../pages/bestelbon/detail/KoppelPartnerAanBestelbonModal";
import {AnnuleerInstallatieModal} from "../../pages/installatie/detail/AnnuleerInstallatieModal";
import {InterventieStelUitModal} from "../../pages/interventie/detail/InterventieStelUitModal";
import {
    DagPlanningStartEindStopAanpassenModal
} from "../../pages/planning/dagplanning/detail/modal/DagPlanningStartEindStopAanpassenModal";

export enum BackOfficeModals {
    BESTELBON_ANNULEER_MODAL = 'BESTELBON_ANNULEER_MODAL',
    BESTELBON_KOPPEL_KLANT_MODAL = 'BESTELBON_KOPPEL_KLANT_MODAL',
    BESTELBON_KOPPEL_PARTNER_MODAL = 'BESTELBON_KOPPEL_PARTNER_MODAL',
    BESTELBON_BEVESTIG_MODAL = 'BESTELBON_BEVESTIG_MODAL',
    BESTELBON_KOPPEL_VERZENDADRES_MODAL = 'BESTELBON_KOPPEL_VERZENDADRES_MODAL',
    BESTELBON_COMMISSIE_VERDELING_MODAL = "BESTELBON_COMMISSIE_VERDELING_MODAL",
    CO2_BESTELLING_ANNULEER_MODAL = 'CO2_BESTELLING_ANNULEER_MODAL',
    INSTALLATIE_STEL_UIT_MODAL = 'INSTALLATIE_STEL_UIT_MODAL',
    INSTALLATIE_ANNULEER_MODAL = 'INSTALLATIE_ANNULEER_MODAL',
    BULK_UPLOAD_DAG_PLANNINGEN_MODAL = 'BULK_UPLOAD_DAG_PLANNINGEN_MODAL',
    MERGE_BEZOEK_AANVRAAG_MODAL = 'MERGE_BEZOEK_AANVRAAG_MODAL',
    UPDATE_BEZOEK_AANVRAAG_VAN_TOT = 'UPDATE_BEZOEK_AANVRAAG_VAN_TOT',
    PLAN_BEZOEK_AANVRAAG_MODAL = 'PLAN_BEZOEK_AANVRAAG_MODAL',
    DAG_PLANNING_VOEG_BEZOEK_TOE = 'DAG_PLANNING_VOEG_BEZOEK_TOE',
    DAG_PLANNING_START_EIND_STOP_AANPASSEN = "DAG_PLANNING_START_EIND_STOP_AANPASSEN",
    GENEREER_BEZOEKAANVRAGEN_MODAL = 'GENEREER_BEZOEKAANVRAGEN_MODAL',
    START_AUTOMATISCHE_PLANNING_MODAL = 'START_AUTOMATISCHE_PLANNING_MODAL',
    GENEREER_ONDERHOUD_SERVICE_ORDER_OPDRACHTEN_MODAL = 'GENEREER_ONDERHOUD_SERVICE_ORDER_OPDRACHTEN_MODAL',
    VERZENDADRESSEN_MAP_MODAL = 'VERZENDADRESSEN_MAP_MODAL',
    BEHANDEL_SERVICE_ORDER_MET_DYNAMICS_MODAL = 'BEHANDEL_SERVICE_ORDER_MET_DYNAMICS_MODAL',
    HERBEZOEK_ONDERHOUD_SERVICE_ORDER_MODAL = 'HERBEZOEK_ONDERHOUD_SERVICE_ORDER_MODAL',
    BEZOEK_AANVRAAG_GROEP_PLAN = 'BEZOEK_AANVRAAG_GROEP_PLAN',
    KOPPEL_KLANT_AAN_KLANT_ACCOUNT_MODAL = 'KOPPEL_KLANT_AAN_KLANT_ACCOUNT_MODAL',
    NIEUWE_KLANT_ACCOUNT_MODAL = 'NIEUWE_KLANT_ACCOUNT_MODAL',
    NIEUWE_CO_BESTELLING_MODAL = 'NIEUWE_CO_BESTELLING_MODAL',
    NIEUWE_KLANT_MODAL = 'NIEUWE_KLANT_MODAL',
    CREATE_VERZEND_ADRES_MODAL = 'CREATE_VERZEND_ADRES_MODAL',
    CREATE_BIJLAGE_MODAL = 'CREATE_BIJLAGE_MODAL',
    ANNULEER_OPVOLGING_ITEM = 'ANNULEER_OPVOLGING_ITEM',
    HERPROBEER_OPVOLGING_ITEM = 'HERPROBEER_OPVOLGING_ITEM',
    NIEUWE_ACCESSOIRE_BESTELLING = 'NIEUWE_ACCESSOIRE_BESTELLING',
    NIEUWE_INTERVENTIE = 'NIEUWE_INTERVENTIE',
    ANNULEER_INTERVENTIE = 'ANNULEER_INTERVENTIE',
    PLAN_OPDRACHT = 'PLAN_OPDRACHT',
    PLAN_INTERVENTIE = 'PLAN_INTERVENTIE',
    VRAAG_KLANT_TOESTEMMING_INTERVENTIE = 'VRAAG_KLANT_TOESTEMMING_INTERVENTIE',
    KLANT_AKKOORD_INTERVENTIE = 'KLANT_AKKOORD_INTERVENTIE',
    INTERVENTIE_UITSTELLEN = "INTERVENTIE_UITSTELLEN",
    NIEUWE_DAGPLANNINGEN_MODAL = 'NIEUWE_DAGPLANNINGEN_MODAL',
    PLANNING_AUTOMATISCH_AANVULLEN_BEVESTIGEN = 'PLANNING_AUTOMATISCH_AANVULLEN_BEVESTIGEN',
    PLANNING_INTERNE_NOTITIES = 'PLANNING_INTERNE_NOTITIES',
    BESTELBON_NIEUW_CONTRACT = 'BESTELBON_NIEUW_CONTRACT',
    ANNULEER_ACCESSOIRE_BESTELLING = 'ANNULEER_ACCESSOIRE_BESTELLING',
}

const MODAL_COMPONENTS = new Map<BackOfficeModals, React.FC<any>>([
    [BackOfficeModals.BESTELBON_ANNULEER_MODAL, AnnuleerBestelBonModal],
    [BackOfficeModals.BESTELBON_KOPPEL_KLANT_MODAL, KoppelKlantAanBestelbonModal],
    [BackOfficeModals.BESTELBON_KOPPEL_PARTNER_MODAL, KoppelPartnerAanBestelbonModal],
    [BackOfficeModals.BESTELBON_BEVESTIG_MODAL, BevestigBestelbonModal],
    [BackOfficeModals.BESTELBON_KOPPEL_VERZENDADRES_MODAL, KoppelVerzendAdressenModal],
    [BackOfficeModals.BESTELBON_COMMISSIE_VERDELING_MODAL, BestelBonCommssieVerdelingModal],
    [BackOfficeModals.CO2_BESTELLING_ANNULEER_MODAL, AnnuleerCo2BestellingModal],
    [BackOfficeModals.INSTALLATIE_STEL_UIT_MODAL, InstallatieStelUitModal],
    [BackOfficeModals.INSTALLATIE_ANNULEER_MODAL, AnnuleerInstallatieModal],
    [BackOfficeModals.BULK_UPLOAD_DAG_PLANNINGEN_MODAL, BulkUploadDagPlanningenModal],
    [BackOfficeModals.GENEREER_BEZOEKAANVRAGEN_MODAL, GenereerBezoekenModal],
    [BackOfficeModals.START_AUTOMATISCHE_PLANNING_MODAL, StartAutomatischePlanningModal],
    [BackOfficeModals.MERGE_BEZOEK_AANVRAAG_MODAL, MergeBezoekAanvraagModal],
    [BackOfficeModals.UPDATE_BEZOEK_AANVRAAG_VAN_TOT, UpdateBezoekAanvraagVanTotModal],
    [BackOfficeModals.GENEREER_ONDERHOUD_SERVICE_ORDER_OPDRACHTEN_MODAL, GenereerOnderhoudServiceOrderOpdrachtenModal],
    [BackOfficeModals.VERZENDADRESSEN_MAP_MODAL, VerzendAdressenMapModal],
    [BackOfficeModals.BEHANDEL_SERVICE_ORDER_MET_DYNAMICS_MODAL, BehandelServiceOrderMetDynamicsModal],
    [BackOfficeModals.HERBEZOEK_ONDERHOUD_SERVICE_ORDER_MODAL, HerbezoekOnderhoudServiceOrderModal],
    [BackOfficeModals.BEZOEK_AANVRAAG_GROEP_PLAN, PlanBezoekAanvraagGroepModal],
    [BackOfficeModals.DAG_PLANNING_VOEG_BEZOEK_TOE, DagPlanningVoegBezoekToeModal],
    [BackOfficeModals.DAG_PLANNING_START_EIND_STOP_AANPASSEN, DagPlanningStartEindStopAanpassenModal],
    [BackOfficeModals.PLAN_BEZOEK_AANVRAAG_MODAL, PlanBezoekAanvraagModal],
    [BackOfficeModals.KOPPEL_KLANT_AAN_KLANT_ACCOUNT_MODAL, KoppelKlantModal],
    [BackOfficeModals.NIEUWE_KLANT_ACCOUNT_MODAL, NieuweKlantAccountModal],
    [BackOfficeModals.NIEUWE_CO_BESTELLING_MODAL, NieuweCo2BestellingModal],
    [BackOfficeModals.NIEUWE_KLANT_MODAL, CreateKlantModalContainer],
    [BackOfficeModals.CREATE_VERZEND_ADRES_MODAL, CreateVerzendAdresModalContainer],
    [BackOfficeModals.CREATE_BIJLAGE_MODAL, CreateBijlageModalContainer],
    [BackOfficeModals.ANNULEER_OPVOLGING_ITEM, AnnuleerOpvolgingItemModal],
    [BackOfficeModals.HERPROBEER_OPVOLGING_ITEM, HerprobeerOpvolgingItemModal],
    [BackOfficeModals.NIEUWE_ACCESSOIRE_BESTELLING, NieuweAccessoireBestellingModal],
    [BackOfficeModals.NIEUWE_INTERVENTIE, NieuweInterventieModal],
    [BackOfficeModals.ANNULEER_INTERVENTIE, AnnuleerInterventieModal],
    [BackOfficeModals.PLAN_OPDRACHT, PlanOpdrachtModal],
    [BackOfficeModals.PLAN_INTERVENTIE, PlanInterventieModal],
    [BackOfficeModals.VRAAG_KLANT_TOESTEMMING_INTERVENTIE, VraagKlantToestemmingModal],
    [BackOfficeModals.KLANT_AKKOORD_INTERVENTIE, KlantAkkoordInterventieModal],
    [BackOfficeModals.INTERVENTIE_UITSTELLEN, InterventieStelUitModal],
    [BackOfficeModals.NIEUWE_DAGPLANNINGEN_MODAL, NieuweDagPlanningenModal],
    [BackOfficeModals.PLANNING_AUTOMATISCH_AANVULLEN_BEVESTIGEN, PlanningAutomatischAanvullenBevestigenModal],
    [BackOfficeModals.PLANNING_INTERNE_NOTITIES, PlanningInterneNotitiesModal],
    [BackOfficeModals.BESTELBON_NIEUW_CONTRACT, NieuwContractModal],
    [BackOfficeModals.ANNULEER_ACCESSOIRE_BESTELLING, AnnuleerAccessoireBestellingModal]
    /* other modals */
]);


const ModalRoot = ({modalStack}) => {
    if (!modalStack || modalStack.length === 0) {
        return null;
    }

    return modalStack
        .map(({type, props}) => {
            const SpecificModal: React.FC<any> | undefined = MODAL_COMPONENTS.get(type);
            if (SpecificModal) {
                return <SpecificModal key={type} {...props} />
            } else {
                return null;
            }
        });
}

export default connect((state: RootState) => ({
    modalStack: state.UI.modalStack
}))(ModalRoot);
