import {default as React, useMemo, useState} from "react";
import {Button, Col, Form, FormGroup, InputGroup} from "react-bootstrap";
import {Form as FormikForm, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import {useHideModal} from "../../../redux/ui/hooks";
import {LoadingButton} from "../../../components/aqualex/LoadingButton";
import {KlantModel} from "../../../redux/klant/types";
import {VerzendAdresModel} from "../../../redux/verzendadres/types";
import {KlantFormField} from "../../../components/aqualex/form/KlantFormField";
import {VerzendAdresFormField} from "../../../components/aqualex/form/VerzendAdresFormField";
import {InterventiePrioriteit, InterventieTaakType, NieuweInterventieForm} from "../../../redux/interventie/types";
import {SimpleFormField} from "../../../components/aqualex/form/SimpleFormField";
import {SkillformField} from "../../../components/aqualex/form/SkillFormField";
import {TextAreaFormField} from "../../../components/aqualex/form/TextAreaFormField";
import {SelectFormField} from "../../../components/aqualex/form/SelectFormField";
import Config from "../../../helpers/Config";
import {OperationeelToestelModel} from "../../../redux/toestel/types";
import {InterventieSkill, Skill} from "../../../redux/planning/types";
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../components/aqualex/Modal";
import {HelpTooltip} from "../../../components/aqualex/HelpTooltip";
import {MultipleEmailAdresFormFieldArray} from "../../../components/aqualex/form/MultipleEmailAdresFormFieldArray";
import {TransportKostConfiguratieForm} from "../../../components/aqualex/form/TransportKostConfiguratieForm";
import {TransportKostConfiguratieType} from "../../../redux/types";
import {ToestelLijstFormField} from "../../../components/aqualex/form/ToestelLijstFormField";
import {PlannersWaarschuwing} from "../../../components/aqualex/PlannersWaarschuwing";
import {
    ArtikelMetAantalFormField,
    ArtikelMetAantalFormValue
} from "../../../components/aqualex/form/ArtikelMetAantalFormField";
import {useTranslation} from "../../../helpers/i18nUtils";

export interface MaakInterventieModalProps {
    onBevestigHandler: (form: NieuweInterventieForm) => Promise<void>;
    initialValues?: NieuweInterventieFormValues;
}

export interface NieuweInterventieFormValues {
    klant?: KlantModel;
    verzendAdres?: VerzendAdresModel;
    toestellen?: OperationeelToestelModel[];

    taakType?: InterventieTaakType;
    taakOmschrijving?: string;
    prioriteit?: InterventiePrioriteit;

    meeTeGevenArtikelen: ArtikelMetAantalFormValue[];
    ticket?: string;
    verwachteDuurtijd: number;
    nodigeSkills: Skill[];
    emailAdressen: { emailAdres: string }[];
    magazijnInstructies: string;
    transportKostType?: string;
    transportKostValue?: number;
    transportKostInherit: boolean;

    "emailAdressen:validate"?();
}

export const NieuweInterventieModal: React.FC<MaakInterventieModalProps> = (props) => {
    const {onBevestigHandler} = props;

    const [verzendAdresId, setVerzendAdresId] = useState<string | undefined>("");
    const [klantId, setKlantId] = useState<string | undefined>("");
    const hideModal = useHideModal();

    const onAnnuleer = () => {
        hideModal();
    };

    const onSubmit = async (values: NieuweInterventieFormValues, helpers: FormikHelpers<any>) => {
        try {
            await onBevestigHandler({
                klantId: values.klant!.id,
                verzendAdresId: values.verzendAdres!.id,
                operationeelToestelIds: values.toestellen!.map(toestel => toestel.id),
                taakType: values.taakType!,
                taakOmschrijving: values.taakOmschrijving!,
                prioriteit: values.prioriteit!,
                meeTeGevenArtikels: values.meeTeGevenArtikelen?.map(item => ({artikelId: item.id, aantal: item.aantal})) || [],
                ticket: values.ticket,
                verwachteDuurtijd: values.verwachteDuurtijd,
                nodigeSkills: values.nodigeSkills,
                emailAdressen: values.emailAdressen?.map(item => item.emailAdres) || [],
                magazijnInstructies: values.magazijnInstructies,
                transportKostType: values.transportKostInherit ? TransportKostConfiguratieType.INHERIT : values.transportKostType as TransportKostConfiguratieType,
                transportKostValue: values.transportKostValue || undefined,
            });

            hideModal();
        } finally {
            helpers.setSubmitting(false);
        }
    };

    const {t} = useTranslation("interventie");

    const toestellenMinimumAantal = 1;
    const taakOmschrijvingMaximaleLengte = 1000;
    const prioriteitMaximaleLengte = 50;
    const ticketMaximaleLengte = 50;
    const minimaalAantalGeselecteerdeSkills = 1;
    const verwachteDuurtijdMinimum = 0;
    const verwachteDuurtijdMaximum = 24 * 60;
    const magazijnInstructieMaximaleLengte = 100;


    const schema = Yup.object({
        klant: Yup.object().nullable()
            .required(t("Foutmeldingen.klant-is-verplicht", "Klant is verplicht") as string),
        verzendAdres: Yup.object().nullable(),
        toestellen: Yup.array()
            .required(t("Foutmelding.toestellen-is-verplicht", "Toestellen is verplicht") as string)
            .min(toestellenMinimumAantal, t("Foutmeldingen.selecteer-ten-minste-een-toestel", "Selecteer tenminste één toestel") as string),
        taakType: Yup.string().required(t("Foutmeldingen.taaktype-is-verplicht", "Taaktype is verplicht") as string),
        taakOmschrijving: Yup.string()
            .required(t("Foutmeldingen.taakomschrijving-is-verplicht", "Taakomschrijving is verplicht") as string)
            .max(taakOmschrijvingMaximaleLengte,
                t("Foutmeldingen.taakomschrijving-mag-maximum-x-tekens-lang-zijn",
                    "Taakomschrijving mag maximum {{maximaleLengte}} tekens lang zijn",
                    {maximaleLengte: taakOmschrijvingMaximaleLengte}) as string),
        prioriteit: Yup.string()
            .required("Prioriteit is verplicht")
            .max(prioriteitMaximaleLengte,
                t("Foutmeldingen.prioriteit-mag-maximaal-x-tekens-lang-zijn",
                    "Prioriteit mag maximum {{maximaleLengte}} tekens lang zijn",
                    {maximaleLengte: prioriteitMaximaleLengte}) as string),
        ticket: Yup.string()
            .max(ticketMaximaleLengte,
                t("Foutmeldingen.ticket-mag-maximum-x-tekens-lang-zijn",
                    "Ticket mag maximum {{maximaleLengte}}}} tekens lang zijn",
                    {maximaleLengte: ticketMaximaleLengte}) as string),
        nodigeSkills: Yup.array()
            .required(t("Foutmeldingen.nodige-skills-is-verplicht",
                "Nodige skills is verplicht") as string)
            .min(minimaalAantalGeselecteerdeSkills, t("Foutmeldingen.selecteer-minstens-een-nodige-skill", "Selecteer tenminste één nodige skill") as string),
        verwachteDuurtijd: Yup.number()
            .required("Verwachte duurtijd per toestel is verplicht")
            .min(verwachteDuurtijdMinimum,
                t("Foutmeldingen.Verwachte-duurtijd-moet-positief-zijn",
                    "Verwachte duurtijd per toestel moet positief zijn") as string)
            .max(verwachteDuurtijdMaximum, "Verwachte duurtijd per toestel mag maximum 24 uur bedragen (1440 min.)"),
        magazijnInstructies: Yup.string()
            .max(magazijnInstructieMaximaleLengte,
                t("Foutmeldingen.magazijnInstructies.maximale-lengte",
                    "Magazijninstructies mag maximum {{maximaleLengte}}}} tekens lang zijn",
                    {maximaleLengte: magazijnInstructieMaximaleLengte}) as string),
        meeTeGevenArtikelen: Yup.array().of(Yup.object().shape({aantal: Yup.number().required(
            t("Foutmeldingen.aantal-is-verplicht", "Aantal is verplicht") as string)}))
    });

    const initialValues = useMemo(() => {
        return {
            ...props.initialValues,
            verwachteDuurtijd: 60,
            transportKostInherit: true
        } as NieuweInterventieFormValues;
    }, [props.initialValues]);

    return (
        <Modal show={true} onHide={onAnnuleer} autoFocus size="lg">
            <ModalHeader closeButton={true}>
                <ModalTitle>{t("Titels.interventie-toevoegen", "Interventie toevoegen")}</ModalTitle>
            </ModalHeader>

            <Formik<NieuweInterventieFormValues> validationSchema={schema}
                                                 validate={(values) => values["emailAdressen:validate"]?.()}
                                                 onSubmit={onSubmit}
                                                 validateOnBlur={false}
                                                 validateOnChange={false}
                                                 initialValues={initialValues}
            >
                {(formikProps) => {
                    const {values, isSubmitting, setFieldValue, errors} = formikProps;

                    const handleChangeKlant = (klant?: KlantModel) => {
                        if (klant?.maximaleInterventieResponsTijd) {
                            setFieldValue("prioriteit", InterventiePrioriteit.SLA);
                        } else {
                            setFieldValue("prioriteit", InterventiePrioriteit.NORMAAL);
                        }
                        setFieldValue("nodigeSkills", [Skill.INTERVENTIE_STANDAARD]);

                        setKlantId(klant?.id);
                        if (klant && klant.verzendAdressen.length === 1) {
                            setFieldValue("verzendAdres", klant.verzendAdressen[0]);
                            setVerzendAdresId(klant.verzendAdressen[0].id);
                        } else {
                            setFieldValue("verzendAdres", undefined);
                            setVerzendAdresId(undefined);
                        }
                        setFieldValue("toestellen", []);
                    };

                    const handleChangeVerzendAdres = (adres?: VerzendAdresModel) => {
                        setVerzendAdresId(adres?.id);
                        setFieldValue("toestellen", []);
                    };

                    return (
                        <FormikForm noValidate>
                            <ModalBody>
                                <h5>{t("Labels.klant-en-verzendadres", "Klant en verzendadres")}</h5>
                                <Form.Row>
                                    <FormGroup id="klant" as={Col}>
                                        <KlantFormField name="klant" onChange={handleChangeKlant}/>
                                    </FormGroup>
                                </Form.Row>
                                <Form.Row>
                                    <FormGroup id="verzendAdres" as={Col}>
                                        {values.klant &&
                                            <VerzendAdresFormField name="verzendAdres" disabled={!values.klant}
                                                                   verzendAdressen={values.klant.verzendAdressen || []}
                                                                   onChange={handleChangeVerzendAdres}/>
                                        }
                                    </FormGroup>
                                </Form.Row>
                                <PlannersWaarschuwing waarschuwing={values.verzendAdres?.plannersWaarschuwing}/>

                                <h5>{t("Labels.toestel", "Toestel")}</h5>
                                <Form.Row>
                                    <FormGroup id="toestellen" as={Col}>
                                        <ToestelLijstFormField name="toestellen" klantId={klantId}
                                                               verzendAdresId={verzendAdresId}
                                                               disabled={!values.klant || !values.verzendAdres}
                                        />
                                    </FormGroup>
                                </Form.Row>

                                <h5>{t("Labels.taakomschrijving", "Taakomschrijving")}</h5>
                                <Form.Row>
                                    <FormGroup id="taakType" as={Col}>
                                        <SelectFormField name="taakType" type="select">
                                            <option value="">{t("NieuweInterventieModal.selecteer-een-type-taak", "Selecteer een type taak...")}</option>
                                            {Object.keys(InterventieTaakType)
                                                .map(type => <option value={type}>{Config.labels[type]}</option>)}
                                        </SelectFormField>
                                    </FormGroup>
                                </Form.Row>
                                <Form.Row>
                                    <FormGroup id="taakOmschrijving" as={Col}>
                                        <TextAreaFormField name="taakOmschrijving"
                                                           placeholder={t("Placeholders.omschrijving", "Omschrijving")}/>
                                    </FormGroup>
                                </Form.Row>

                                <Form.Row>
                                    <FormGroup id="prioriteit" as={Col}>
                                        <h5>{t("Labels.prioriteit", "Prioriteit")}</h5>
                                        <SelectFormField name="prioriteit" type="select">
                                            <option/>
                                            {Object.keys(InterventiePrioriteit).map(prioriteit => <option
                                                value={prioriteit}>{Config.labels[prioriteit]}</option>)}
                                        </SelectFormField>
                                    </FormGroup>

                                    <FormGroup id="nodigeSkills" as={Col}>
                                        <h5>{t("Labels.skills", "Skills")}</h5>
                                        <SkillformField name="nodigeSkills" skillEnum={InterventieSkill}/>
                                    </FormGroup>
                                </Form.Row>

                                <Form.Row>
                                    <FormGroup id="speciale-afspraken" as={Col} md={6} xs={12}>
                                        <h5>{t("Labels.speciale-afspraken", "Speciale afspraken")}</h5>

                                        <h6>{t("Labels.transportkost", "Transportkost")}</h6>
                                        <TransportKostConfiguratieForm
                                            inheritLabel={t("Labels.overnemen-van-verzendadres-of-klant", "Overnemen van verzendadres of klant")}/>
                                    </FormGroup>
                                </Form.Row>

                                <Form.Row>
                                    <FormGroup id="ticket" as={Col}>
                                        <h5>{t("Labels.ticket-zendesk", "Ticket Zendesk")}</h5>
                                        <SimpleFormField name="ticket"/>
                                    </FormGroup>

                                    <FormGroup id="verwachteDuurtijd" as={Col}>
                                        <h5>{t("Labels.verwachte-duurtijd-per-toestel", "Verwachte duurtijd per toestel")}</h5>
                                        <InputGroup hasValidation>
                                            <SimpleFormField name="verwachteDuurtijd" type="number"
                                                             showFeedback={false}/>

                                            <InputGroup.Append>
                                                <InputGroup.Text>{t("Labels.minuten-afgekort", "min.")}</InputGroup.Text>
                                            </InputGroup.Append>

                                            {errors.verwachteDuurtijd && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.verwachteDuurtijd}
                                                </Form.Control.Feedback>
                                            )}
                                        </InputGroup>
                                    </FormGroup>
                                </Form.Row>

                                <h5>{t("Labels.mee-te-geven-artikelen", "Mee te geven artikelen")}</h5>
                                <Form.Row>
                                    <FormGroup id="meeTeGevenArtikelen" as={Col}>
                                        <ArtikelMetAantalFormField name="meeTeGevenArtikelen"
                                                                   bedrijfId={values?.klant?.bedrijfId}
                                                                   isMulti/>
                                    </FormGroup>
                                </Form.Row>

                                <h5>{t("Labels.magazijninstructies", "Magazijninstructies")}</h5>
                                <Form.Row>
                                    <FormGroup id="magazijnInstructies" as={Col}>
                                        <TextAreaFormField name="magazijnInstructies"/>
                                    </FormGroup>
                                </Form.Row>

                                <h5>{t("Labels.e-mail-adressen", "E-mailadressen")}{' '}
                                    <HelpTooltip id='tooltip-emailAdressen'>
                                        {t("NieuweInterventieModal.e-mail-adressen-tooltip", "Email-adressen die notificaties moeten ontvangen.")}
                                    </HelpTooltip>
                                </h5>
                                <Form.Row>
                                    <FormGroup id="emailAdressen" as={Col}>
                                        <MultipleEmailAdresFormFieldArray/>
                                    </FormGroup>
                                </Form.Row>
                            </ModalBody>
                            <ModalFooter>
                                <Button variant="light" onClick={onAnnuleer}>{t("algemeen:Buttons.annuleer", "Annuleer")}</Button>
                                <LoadingButton disabled={isSubmitting} loading={isSubmitting}
                                               variant="primary" type="submit">{t("algemeen:Buttons.toevoegen", "Toevoegen")}</LoadingButton>
                            </ModalFooter>
                        </FormikForm>
                    );
                }}
            </Formik>
        </Modal>
    );
};
